import styled from 'styled-components';

const VisitorSectionWrapper = styled.section`
  min-height: 630px;
  display: flex;
  align-items: center;
  margin: 6rem 0;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    margin-bottom: 45px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    margin: 60px 0;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
    margin-bottom: 100px;
  }
`;

const CheckColumnOne = styled.div`
  display: inline-block;
  width: 100%;
`;

const SectionObject = styled.div`
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    right: auto;
  }
  img {
    max-width: 93%;
    height: auto;
  }
  .objectWrapper {
    margin-right: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 3vw;
      right: 0;
      width: 90%;
    }
  }
`;

export { SectionObject, CheckColumnOne };

export default VisitorSectionWrapper;
