import styled from 'styled-components';

const CampaignPlannerWrapper = styled.section`
  min-height: 630px;
  display: flex;
  align-items: center;
  padding: 8rem 0;
  position: relative;
  background: rgb(239 240 252) 25.01%;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    padding-bottom: 45px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    padding: 60px 0;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
    padding-bottom: 100px;
  }
`;

const CheckColumnOne = styled.div`
  display: inline-block;
  width: 100%;
`;

const SectionObject = styled.div`
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
  img {
    max-width: 93%;
    height: auto;
  }
  .objectWrapper {
    margin-left: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 3%;
      width: 85%;
      @media (max-width: 767px) {
        width: 100%;
        top: 0vw;
        img {
          margin: auto;
        }
      }
    }
  }
`;

export { SectionObject, CheckColumnOne };

export default CampaignPlannerWrapper;
