import React from 'react';
import PropTypes from 'prop-types';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {
  SectionObject,
  CheckColumnOne,
} from './analyse.style';
import TextHr from 'common/src/components/textLine';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import Fade from 'react-reveal/Fade';
import SequenceView from 'common/src/assets/image/saas/sequence_view_large.png';
import CheckedText from 'common/src/components/checkedText';
import {
  TopBootstrap,
  BottomBootstrap,
} from 'common/src/components/bootstrapView/index.style';

const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
  handleOpenDemoModal,
  containedBtnStyle,
}) => {
  const ImageView = () => (
    <SectionObject>
      <Card className="objectWrapper" {...imageWrapper}>
        <Zoom>
          <Image src={ImageBg} alt="BgImage" />
        </Zoom>
        <Card className="dashboardWrapper" {...imageWrapper}>
          <Fade right delay={90}>
            <img
              src={SequenceView}
              alt="extension visual"
              style={{
                boxShadow: '7px 11px 23px 2px grey',
                borderRadius: '5px',
              }}
            />
          </Fade>
        </Card>
      </Card>
    </SectionObject>
  );
  return (
    <VisitorSectionWrapper id="visitorSection">
      <TopBootstrap>
        <ImageView />
      </TopBootstrap>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text content="LEARN WHAT WORKS" {...sectionSubTitle} />
                <Heading content="Measure at every touch point." {...title} />
              </>
            }
            description={
              <div style={{ marginBottom: '20px' }}>
                <Text
                  content="Leverage multi channel sequence and engage in a modern fashion. By leveraging sequence no prospect will be left untouched."
                  {...description}
                />
                <CheckColumnOne style={{ paddingLeft: '10px' }}>
                  <CheckedText text="Automatically analyse reply and bounce rates" />
                  <CheckedText text="Analyse per step and full sequence" />
                  <CheckedText text="Learn what works and what not" />
                </CheckColumnOne>
              </div>
            }
            button={
              <Button
                title="GET DEMO"
                {...containedBtnStyle}
                style={{ marginLeft: '0' }}
                onClick={handleOpenDemoModal}
              />
            }
          />
        </Box>
      </Container>
      <BottomBootstrap>
        <ImageView />
      </BottomBootstrap>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  containedBtnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    marginTop: ['2rem', '3.5rem'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
